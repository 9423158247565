.notification-banner {
  color: #fff;
  padding: 10px;
  position: relative;
  margin-left: 5px;
  /* top: 0; */
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-banner p {
  margin: 0;
}

.notification-banner button {
  background: none;
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.notification-banner button:hover {
  text-decoration: underline;
}
